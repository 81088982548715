/* .temp13-inner::before {
    position: absolute;
    right: 0;
    bottom: 0;
    content: '';
    width: 1253px;
    height: 713px;
    background: url(./assets/images/about-video.png) no-repeat 0 0;
} */

.temp14-txt h4:before {
    width: 68px;
    height: 58px;
    display: block;
    position: relative;
    margin-bottom: 30px;
    content: '';
    border-radius: 72px;
    background: #ec911e url(./assets/svg/quote.svg) no-repeat center;
}

html{scroll-behavior:smooth}